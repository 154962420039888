/*

 

Template Name: Velzon - Admin & Dashboard Template

 

Author: Themesbrand

 

Website: https://themesbrand.com/

 

Contact: support@themesbrand.com

 

File: Custom Css File

 

*/

// .css-b62m3t-container{

//     .css-1s2u09g-control{

//         background-color: var(--#{$prefix}gray-200);

//         border-color: var(--#{$prefix}border-color);

//         &:hover {

//             border-color: var(--#{$prefix}border-color);

//         }

//     }

//     .css-1pahdxg-control{

//         background-color: var(--#{$prefix}gray-200);

//         border-color: var(--#{$prefix}border-color);

//         box-shadow: none;

//         &:hover {

//             border-color: var(--#{$prefix}border-color) !important;

//         }

//     }

//     .css-26l3qy-menu {

//         background-color: $dropdown-bg;

//         .css-1n7v3ny-option{

//             cursor: pointer;

//             background-color: $dropdown-link-hover-bg;

//             &:active {

//                 background-color: $dropdown-link-hover-bg;

//             }

//         }

//     }

//     .css-1rhbuit-multiValue{

//         background-color: var(--#{$variable-prefix}light);

//     }

//     .css-12jo7m5{

//         color:$heading-color;

//     }

//     .css-6j8wv5-Input,  .css-qc6sy-singleValue{

//         color:$heading-color;

//     }

// }

// .bxs-edit:hover,

// .ri-delete-bin-6-line:hover {

//   color: #fff !important;

// }
// .css-b62m3t-container{
//   z-index: 1500;
// }
.sc-dmsloy .fnfHDp {
  z-index: 5;
}

.obsselect {
  z-index: 2;
}

.txdrop {
  z-index: 10;
}

// .css-lnmdiq5-menu {
//   z-index: 2900 !important;
// }
// #react-select-3-listbox {
//   z-index: 2900 !important;
// }
.span-hide {
  display: block;

  width: 220px;

  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;
}

.loader {
  position: fixed;

  top: 0px !important;

  left: 0px !important;

  bottom: 0px;

  right: 0px;

  height: 100vh;

  width: 100vw;

  background-color: #878a99;

  opacity: 0.5;
}

.highRisk {
  background-color: rgb(235, 221, 221);
  padding: 0px 5px;
  color: red;

  border-radius: 5px;
}

.mediumRisk {
  background-color: rgb(247, 227, 190);
  padding: 0px 5px;
  color: orange;

  border-radius: 5px;
}

.lowRisk {
  background-color: rgb(228 245 206) !important;
  color: green;
  border-radius: 5px;
  padding: 0px 5px;
}

.customCanvas {
  width: 58% !important;
}

.assetSummnary {
  max-height: 68vh;

  overflow-y: auto;
}

.asset-component-card {
  min-height: 80vh;

  .header {
    background: #dedede;

    display: flex;

    align-items: center;

    justify-content: space-between;

    h3 {
      text-align: center;

      font-size: 20px;
    }

    .status {
      display: flex;

      align-items: center;

      justify-content: center;
    }
  }

  .body {
    .card-div {
      max-height: 60vh;

      overflow-y: auti;
    }

    .button {
      display: flex;

      align-items: center;

      justify-content: flex-end;
    }

    .card {
      padding-bottom: 0;

      margin-bottom: 0;

      box-shadow: none;

      .card-header {
        border-bottom: none;

        .img {
          display: flex;

          align-items: center;

          justify-content: flex-end;
        }
      }

      .cardImg {
        flex: 0.3;

        width: 100%;

        height: 100%;

        object-fit: contain;
      }

      .info {
        flex: 0.7;

        .info_title {
          display: flex;

          align-items: center;

          justify-content: space-between;
        }

        .info_data {
          display: flex;

          align-items: center;

          justify-content: space-between;

          .operability {
            display: flex;

            justify-content: space-between;
          }
        }
      }

      .optional {
        display: flex;

        .form-control {
          width: 37%;
        }
      }
    }
  }

  .cardImges {
    width: 250px;
    height: 250px;
    border: none !important;
  }

  .card-footer {
    display: flex;

    align-items: center;

    justify-content: center;

    button {
      min-width: 100px;
    }
  }
}

.scrollVertical {
  max-height: 40vh;

  overflow-y: auto;
}

.scrollVeticalTwo {
  max-height: 56vh;

  overflow-y: scroll;
}

.scrollVerticalThree {
  max-height: 71vh;

  overflow-y: auto;
}

.scrollContainer {
  width: 100%;

  max-width: 900px;

  overflow-x: auto;
}

.scrollHorizontalCard {
  width: fit-content;

  display: flex;
}

.scrollContent {
  width: 215px;

  margin-right: 13px;
}

.fNHslB {
  width: 100px !important;
}

.sidebarList {
  list-style: none;
}

.modal {
  --bs-modal-width: 800px;
}

.cross {
  position: absolute;

  right: 15px;
}

label {
  font-size: 16px !important;
}

.p-divider-content {
  z-index: 0 !important;
}

.brd-none {
  border-radius: 0px;
}

// .rdt_TableCol {
//   border-bottom: 1px solid #eee;

//   background-color: #eee;

//   min-width: 150px !important;
// }

// .rdt_TableCell {
//   min-width: 150px !important;
// }

// .p-calendar {
//   width: 160px !important;

//   height: 40px !important;
// }

.top--10 {
  top: -40px;
}

.error {
  color: red;

  font-size: 14px;

  font-weight: 400;
}

.w-60 {
  width: 60% !important;
}

.h-76 {
  height: 76vh;
}

.w-40 {
  width: 40% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.wd-10 {
  min-width: 100px !important;
}

.top--0 {
  top: -20px;
  height: 40px !important;
  width: fit-content !important;
  overflow: hidden;

  border-radius: 3px;
}

.bg-none {
  background: transparent;
}

.rdt_TableHeadRow {
  font-size: 16px;
  font-weight: 400400;
}

.backgroundVideoPix {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 1200px !important;
  overflow: hidden;
}

.bg-primary {
  background-color: #003975 !important;
}

.scroll {
  height: 100vh !important;
  overflow-y: auto !important;
  padding-bottom: 120px;
}

.card-status-completed {
  position: relative;

  top: -17px;

  right: -17px;

  padding: 3px 10px;

  background: rgb(62, 199, 34);

  color: #ffffff;

  border-radius: 3px;
}

.card-status-completed1 {
  position: relative;

  top: -7px;

  right: -7px;

  padding: 3px 10px;

  background: rgb(62, 199, 34);

  color: #ffffff;

  border-radius: 3px;
}

.breadcrumb-item a {
  color: rgb(51, 153, 102) !important;
}

.cross {
  position: absolute;

  right: 10px;

  cursor: pointer;
}

a {
  text-decoration: none;

  color: #000 !important;
}

a:hover {
  color: #2aa99291 !important;
}

a.active {
  color: #2aa99291 !important;

  background-color: #2aa99240 !important;
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.pagination .active a {
  background-color: #2aa992;
}

.pagination .active > .page-link {
  color: #ffffff !important;
}

.pagination .page-link {
  color: #2aa992 !important;
}

.pagination .disabled > .page-link {
  color: #878a99 !important;
}

.pagination .page-item {
  border-radius: 5px;

  margin: 3px;
}

.calender {
  display: flex;

  flex-direction: row;

  justify-content: center;

  align-items: center;
}

.marquee {
  width: 100%;

  overflow: hidden;

  position: relative;

  height: 22px;

  line-height: 2;
}

.marquee div {
  display: block;

  width: 200%;

  height: 30px;

  position: absolute;

  overflow: hidden;

  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;

  width: 50%;
}

.modal-header {
  background-color: #2aa992;

  border: 0;

  color: #ffffff !important;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

$sizes: 500;

// width

@mixin width-classes {
  @for $i from 1 through $sizes {
    $width: $i * 1px;

    .width-#{$i} {
      width: $width;
    }
  }
}

@mixin width-classes {
  @for $i from 1 through $sizes {
    $width: $i * 1%;

    .pwidth-#{$i} {
      width: $width;
    }
  }
}

@include width-classes;

@mixin top-classes {
  @for $i from 1 through $sizes {
    $top: $i * 1px;

    .tops-#{$i} {
      top: $top;
    }
  }
}

@include top-classes;

@mixin left-classes {
  @for $i from 1 through $sizes {
    $left: $i * 1px;

    .lefts-#{$i} {
      left: $left;
    }
  }
}

@include left-classes;

@mixin height-classes {
  @for $i from 1 through $sizes {
    $height: $i * 1px;

    .height-#{$i} {
      height: $height;
    }
  }
}

@include height-classes;

@mixin z-index-classes {
  @for $i from 1 through $sizes {
    $zindex: $i * 1px;

    .z-#{$i} {
      z-index: $zindex;
    }
  }
}

@include z-index-classes;

@mixin margin-bottom {
  @for $i from 1 through $sizes {
    $mb: $i * 1px;

    .margin-bottom-#{$i} {
      margin-bottom: $mb;
    }
  }
}

@include margin-bottom;

@mixin margin-top {
  @for $i from 1 through $sizes {
    $mb: $i * 1rem;

    $mbpx: $i * 1px;

    .margin-top-rem-#{$i} {
      margin-top: $mb !important;
    }

    .margin-top-#{$i} {
      margin-top: $mbpx !important;
    }
  }
}

@include margin-top;

.dropdown-highlight {
  color: $orange-1;
}

@mixin padding-left-classes {
  @for $i from 1 through $sizes {
    $padding-left: $i * 1rem;

    .padding-left-rem-#{$i} {
      padding-left: $padding-left;
    }
  }
}

@include padding-left-classes;

@mixin bottom-classes {
  @for $i from 1 through $sizes {
    $bottom: $i * 1px;

    .bottom-#{$i} {
      bottom: $bottom;
    }
  }
}

@include bottom-classes;

@media (max-width: 810px) {
  .display-setting {
    display: none;
  }
}

@media (min-width: 810px) {
  .icon-top-tab {
    top: 160px !important;
  }
}

.offcanvas-title {
  color: #fff !important;
}

.GOOD,
.LOW,
.good,
.low {
  background: rgb(168, 212, 168);
  padding: 5px 10px;
  color: green;
}

.MEDIUM_RISK,
.MEDIUM,
.medium {
  background: rgb(238, 232, 163);
  padding: 5px 10px;
  // color: orange;
  color: yellow;
}

.HIGH_RISK,
.HIGH,
.high {
  background: rgb(236, 173, 173);
  padding: 5px 10px;
  color: red;
}

.MAJOR,
.major {
  background: rgb(236, 211, 173);
  padding: 5px 10px;
  color: orange;
}

.MINOR,
.minor {
  background: rgb(215, 173, 236);
  padding: 5px 10px;
  color: purple;
}

.ril__errorContainer {
  display: none;
}

.sliderforimages {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 10vh;
  width: 85%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

//draw imgae

.editor {
  position: absolute;
  display: flex;
  width: 80vw;
  height: 70vh;
  overflow: hidden;
  margin-left: 8vw;
  margin-right: 15vw;
}

.toolbar {
  position: fixed;
  right: 0;
  top: 8%;
  width: 80px;
  height: 100%;
  // z-index: 1;
}

.toolbar-item {
  padding: 15px;
}

.tool-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.tool-input {
  width: 100%;
}

.image-area {
  flex-grow: 1;
  padding: 20px;
  background: #555555;
}

#canvas {
  max-width: 100%;
  max-height: 100%;
}
